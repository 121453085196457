import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import {LandingContext} from '@context/LandingContext';

import {useAppSelector} from '@store/hooks/hooks';

import Button from '@components/common/buttons/Button';
import { IconPosition } from '@components/common/buttons/buttonStyles';
import {Colors} from '@components/styledComponents/base/Colors';
import { typography } from '@components/styledComponents/base/typography';

import PhoneIcon from '@public/landing/phone_new.svg';

type ButtonType = 'stroke' | 'plainButton';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    styleType?: ButtonType,
    isWhite?: boolean,
    noText?: boolean
};

const text = {
    ru: 'Обратный звонок',
    en: 'Callback',
    uz: "Qayta qo'ng'iroq",
    lt: ''
};

const applicationTitle = {
    ru: 'Обратный звонок',
    en: 'Callback',
    uz: "Qayta qo'ng'iroq",
    lt: ''
};

export default function Callback (props: Props) {
    
    const {isWhite, noText, styleType = 'filled', ...rest} = props;
    const {language} = useAppSelector(state => state.generalStatesSlice);
    const {setApplicationFormOptions} = React.useContext(LandingContext);

    if(styleType === 'plainButton') {
        return(
            <Button
                isSmallHeight
                isFit
                Icon={<PhoneIcon />}
                iconPosition={IconPosition.LEFT}
                onClick={()=>setApplicationFormOptions({isOpen: true, title: applicationTitle})} {...rest}>
                {text[language]}
            </Button>
        );
    }

    return (
        <Container 
            isBlack={isWhite} 
            onClick={()=>setApplicationFormOptions({isOpen: true, title: applicationTitle})}
            {...rest}>  
            <PhoneIcon />                
            {!noText && 
              <span>
                  {text[language]}
              </span>}             
        </Container>
    );

}

const Container = styled.button<{ isBlack?: boolean}>`
  ${typography.body};
  height: 34px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  border-radius: 10px;
  transition: background-color 0.5s, border 0.5s, color 0.5s;

    border: ${({ isBlack }: any) => isBlack ? `1px solid ${Colors.GRAY_400}` : `1px solid ${Colors.GRAY_800}`};
    color: ${({ isBlack }: any) => isBlack ? Colors.WHITE : Colors.GRAY_800};

    svg {
    fill: ${({ isBlack }: any) => isBlack ? Colors.WHITE : Colors.GRAY_800};
    }

    &:hover {
    background-color: ${({ isBlack }: any) => isBlack ? Colors.WHITE : Colors.GRAY_800};
    color: ${({ isBlack }: any) => !isBlack ? Colors.WHITE : Colors.GRAY_800};

    svg {
        fill: ${({ isBlack }: any) => !isBlack ? Colors.WHITE : Colors.GRAY_800};
    }
    }

    svg {
        height: 24px;
        width: 24px;
        transition: fill 0.5s;
        }

    @media (max-width: 780px) {
        width: 34px;
        height: 34px;
        padding: 0;
        & > :nth-child(2) {
        display: none;
        }
    }

`;
