import React from 'react';

const about = {
    title: {
        ru: (
            <>
        У вас есть вопросы о сервисе? <br /> Напишите нам прямо сейчас
            </>
        ),
        en: (
            <>
        Do you have any questions left? <br /> Write to us right now
            </>
        ),
        lt: (
            <>
        Ar nori eiti į darbą? <br /> Galėsi įsidarbinti
            </>
        ),
        uz: <>
            Sizda xizmat haqida savollar bormi?
            <br/>
            Bizga hoziroq yozing!
        </>
    },
    button: {
        ru: `Написать`,
        en: `Write`,
        lt: `Parašyk žinutę`,
        uz: `yozish `
    },
    phone: {
        ru: `Телефон`,
        en: `Phone number`,
        lt: `Telefonas`,
        uz: `telefon`,
    },
    email: {
        ru: `Почта`,
        en: `Email`,
        lt: `paštas`,
        uz: `Pochta`
    },
    address: {
        ru: `Адрес`,
        en: `address `,
        lt: `adresas`,
        uz: `Manzil`
    },
    addressAtMap: {
        ru: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        en: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        lt: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        uz: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
    },
    companyTitle: {
        ru: `КОМПАНИЯ`,
        en: `COMPANY`,
        lt: `ĮMONĖ`,
        uz: `KOMPANIYA`
    },
    company: {
        ru: <>PT Fable Teknologi Nusantara</>,
        en: <>PT Fable Teknologi Nusantara</>,
        lt: <>PT Fable Teknologi Nusantara</>,
        uz: <>PT Fable Teknologi Nusantara</>,
    },
    addressCountry: {
        ru: ``,
        en: ``,
        lt: ``,
        uz: ``
    },
    addressLocality: {
        ru: ``,
        en: ``,
        lt: ``,
        uz: ``
    },
    addressStreet: {
        ru: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        en: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        lt: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
        uz: <>Menara Global Lantai 12, Jalan Jend Gatot Subroto Kav 27</>,
    },
    formTitle: {
        ru: `Задать вопрос`,
        en: `Ask a question `,
        lt: `Užduoti klausimą`,
        uz: `Savol bering`
    },
    question: {
        ru: `Ваш вопрос`,
        en: `Question `,
        lt: `jūsų`,
        uz: `Sizning savolingiz`
    },
    name: {
        ru: `Имя`,
        en: `Name `,
        lt: `Vardas`,
        uz: `Ismi`
    },
    nuance: {
        ru: `Нажимая на кнопку, вы даете согласие на обработку`,
        en: `By clicking the 'Apply' button you accept the terms of`,
        lt: `Spausdami mygtuką „Pateikti paraišką“ sutinkate su`,
        uz: `Tugmani bosish orqali siz`
    },
    personalData: {
        ru: `персональных данных`,
        en: `personal formData processing.`,
        lt: `tvarkymo sąlygomis.`,
        uz: `shaxsiy ma'lumotlarni`
    },
    nuance2: {
        ru: '',
        en: '',
        lt: '',
        uz: `qayta ishlashga rozilik bildirasiz`
    }

};


export default about;
