
const header = {
    toWhomText: {
        ru: `Для кого`,
        en: `For Whom`,
        lt: `Kam skirta`,
        uz: `Kim uchun`,
    },
    freelancersText: {
        ru: `Фрилансерам`,
        en: `For freelancers`,
        lt: `Laisvai samdomas darbuotojas`,
        uz: `Frilanserlar uchun`,
    },
    toCustomersText: {
        ru: `Компаниям`,
        en: `For customers`,
        lt: `Užsakovams`,
        uz: `Кompaniyasi`
    },
    featuresAndTariffs: {
        ru: `Функции и тарифы`,
        en: `Pricing`,
        lt: `Funkcijos ir tarifai`,
        uz: `Funktsiyalar va tariflar`,
    },
    usageScenarios: {
        ru: `Сценарии использования`,
        en: `Usage Scenarios`,
        lt: `Naudojimo šablonai`,
        uz: `Foydalanish stsenariylari`
    },
    AboutTheService: {
        ru: `О сервисе`,
        en: `About the Service`,
        lt: `Apie paslaugas`,
        uz: `Xizmat haqida`
    },
    AboutTheCompany: {
        ru: `О компании`,
        en: `About the Company`,
        lt: `Apie kompaniją`,
        uz: `Kompaniya haqida `
    },
    FAQText: {
        ru: `FAQ`,
        en: `FAQ`,
        lt: `DUK`,
        uz: `KSS `
    },
    ContactsText: {
        ru: `Контакты`,
        en: `Contacts`,
        lt: `Kontaktai`,
        uz: `Aloqa usullari`
    },
    SignInText: {
        ru: `Вход`,
        en: `Log in`,
        lt: `Prisijungti`,
        uz: `Kirish`
    },
    RegistrationText: {
        ru: `Регистрация`,
        en: `Sign Up`,
        lt: `Registracija`,
        uz: `Ro'yxatdan o'tish`
    },
    blogText: {
        ru: `Блог`,
        en: `Blog`,
        lt: `Tinklaraštis`,
        uz: `Blog`
    },
    menuText: {
        ru: `Меню`,
        en: `Menu`,
        lt: `Menu`,
        uz: `Menyu`
    },
    Api:{
        ru: `API`,
        en: `API`,
        lt: `API`,
        uz: `API`
    }
};


export default header;