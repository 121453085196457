const footer = {
    toWhom: {
        ru: `Кому`,
        en: `More Information`,
        lt: `Kam skirta`,
        uz: `Kimga`
    },
    toCustomers: {
        ru: `Заказчикам`,
        en: `For customers`,
        lt: `Užsakovams`,
        uz: `Buyurtmachilarga`
    },
    address: {
        ru: `Адрес`,
        en: `Address`,
        lt: `Adresas`,
        uz: `Manzil`
    },
    toFreelancers: {
        ru: `Фрилансерам`,
        en: `For freelancers`,
        lt: `Frilanseriams`,
        uz: `Frilanserlarga`
    },
    rulesOfUse: {
        ru: `Правила пользования сайтом`,
        en: `Rules of use`,
        lt: `Taisyklės`,
        uz: `Saytdan foydalanish qoidalari`
    },
    aboutTheService: {
        ru: `О сервисе`,
        en: `About the Service`,
        lt: `Apie paslaugas`,
        uz: `Xizmat haqida`
    },
    whyUs: {
        ru: `Почему мы`,
        en: `Why us`,
        lt: `Kodėl mes`,
        uz: `Nima uchun biz`
    },
    documents: {
        ru: `Документы`,
        en: `Documents`,
        lt: `Dokumentas`,
        uz: `Hujjatlar`
    },
    company: {
        ru: 'Компания',
        en: 'Company',
        lt: 'Įmonė',
        uz: 'Kompaniya'
    },
    toCompanies: {
        ru: `Заказчикам`,
        en: `For customers`,
        lt: `Užsakovams`,
        uz: `Buyurtmachilarga`
    },
    aboutTheCompany: {
        ru: `О компании`,
        en: `About the company`,
        lt: `Apie įmonę`,
        uz: `Biz haqimizda`
    },
    aboutUs: {
        ru: `О нас`,
        en: `About us`,
        lt: `Apie mus`,
        uz: `Biz haqimizda`
    },
    toPartners: {
        ru: `Партнерам`,
        en: `To Partners`,
        lt: `Partneriams`,
        uz: `Hamkorlar uchun`
    },
    blogText: {
        ru: `Блог`,
        en: `Blog`,
        lt: `Tinklaraštis`,
        uz: `Blog`
    },
    FAQ: {
        ru: `FAQ`,
        en: `FAQ`,
        lt: `DUK`,
        uz: `KSS`
    },
    contacts: {
        ru: `Контакты`,
        en: `Contacts`,
        lt: `Kontaktai`,
        uz: `Aloqa usullari`
    },
    offer: {
        ru: `Оферта`,
        en: `Public offer`,
        lt: `Pasiūlymas`,
        uz: `Taklif`
    },
    termsOfUse: {
        ru: `Условия использования`,
        en: `User agreement`,
        lt: `Naudojimo sąlygos`,
        uz: `Foydalanish shartlari`
    },
    usageScenario: {
        ru: `Сценарий использования`,
        en: `Usage scenario`,
        lt: `Scenarijų naudojimas`,
        uz: `Foydalanish stsenariysi`
    },
    functionsAndRates: {
        ru: `Функции и тарифы`,
        en: `Pricing`,
        lt: `Funkcijos ir tarifai`,
        uz: `Funktsiyalar va tariflar`,
    },
    knowledgeBase: {
        ru: `База знаний`,
        en: `Knowledge base`,
        lt: `Žinių bazė`,
        uz: `Ma'lumot bazasi`
    },
    privacyPolicy: {
        ru: `Политика конфиденциальности`,
        en: `Privacy policy`,
        lt: `Privatumo politika`,
        uz: `Maxfiylik siyosati`
    },
    theOffer: {
        ru: `Оферта`,
        en: `The offer`,
        lt: `Pasiūlymu`,
        uz: `Taklif`
    },
    userAgreement: {
        ru: `Пользовательское соглашением`,
        en: `User agreement`,
        lt: `Vartotojo sutartis`,
        uz: `Foydalanuvchi shartnomasi`
    },
    workingRegime: {
        ru: `Режим работы`,
        en: `Office hours`,
        lt: `Darbo režimas`,
        uz: `Ish rejimi`
    },
    mondayFriday: {
        ru: `Пн-пт`,
        en: `Mon-Fri`,
        lt: `Pn-Pt`,
        uz: `Dush-Pay`
    },
    documentation: {
        ru: `Документация`,
        en: `Documentation`,
        lt: `Dokumentacija`,
        uz: `Hujjatlar`
    },
    rewardProgram: {
        ru: `Партнерская программа`,
        en: `Reward program`,
        lt: `Partnerių programa`,
        uz: `Hamkorlik dasturi`
    },
    Api: {
        ru: `API`,
        en: `API`,
        lt: `API`,
        uz: `API `
    }
};


export default footer;