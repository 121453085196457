import React from 'react';

const about = {
    title: {
        ru: (
            <>
        У вас есть вопросы о сервисе? <br /> Напишите нам прямо сейчас
            </>
        ),
        en: (
            <>
        Do you have any questions left? <br /> Write to us right now
            </>
        ),
        lt: (
            <>
        Ar nori eiti į darbą? <br /> Galėsi įsidarbinti
            </>
        ),
        uz: <>
            Sizda xizmat haqida savollar bormi?
            <br/>
            Bizga hoziroq yozing!
        </>
    },
    button: {
        ru: `Написать`,
        en: `Write`,
        lt: `Parašyk žinutę`,
        uz: `yozish `
    },
    companyTitle: {
        ru: `Компания`,
        en: `Company`,
        lt: `Įmonė`,
        uz: `Kompaniya`
    },
    phone: {
        ru: `Телефон`,
        en: `Phone number`,
        lt: `Telefonas`,
        uz: `telefon`,
    },
    email: {
        ru: `Почта`,
        en: `Email`,
        lt: `paštas`,
        uz: `Pochta`
    },
    company: {
        ru: '',
        en: '',
        lt: '',
        uz: ``
    },
    address: {
        ru: `Адрес`,
        en: `address `,
        lt: `adresas`,
        uz: `Manzil`
    },
    addressAtMap: {
        ru: `Литва, Вильнюс, улица В. Нагевичяус, 3`,
        en: `Lithuania, Vilnius, V. Nageviciaus street, 3`,
        lt: `Lithuania, Vilnius, V. Nageviciaus street, 3`,
        uz: `Lithuania, Vilnius, V. Nageviciaus street, 3`
    },
    addressCountry: {
        ru: `Узбекистан`,
        en: `Uzbekistan`,
        lt: `Uzbekistanas`,
        uz: `O'zbekiston`
    },
    addressLocality: {
        ru: `Ташкент`,
        en: `Tashkent`,
        lt: `Taškentas`,
        uz: `Toshkent`
    },
    addressStreet: {
        ru: `Махаллинский комитет Миробод, улица Нукус, дом 86/3`,
        en: `Mihalla Committee of Mirobod, 86/3 Nukus Street`,
        lt: `Mihalla Committee of Mirobod, 86/3 Nukus Street`,
        uz: `Mahalla qo‘mitasi Mirobod, Nukus ko‘chasi, 86/3-uy`
    },
    formTitle: {
        ru: `Задать вопрос`,
        en: `Ask a question `,
        lt: `Užduoti klausimą`,
        uz: `Savol bering`
    },
    question: {
        ru: `Ваш вопрос`,
        en: `Question `,
        lt: `jūsų`,
        uz: `Sizning savolingiz`
    },
    name: {
        ru: `Имя`,
        en: `Name `,
        lt: `Vardas`,
        uz: `Ismi`
    },
    nuance: {
        ru: `Нажимая на кнопку, вы даете согласие на обработку`,
        en: `By clicking the 'Apply' button you accept the terms of`,
        lt: `Spausdami mygtuką „Pateikti paraišką“ sutinkate su`,
        uz: `Tugmani bosish orqali siz`
    },
    personalData: {
        ru: `персональных данных`,
        en: `personal formData processing.`,
        lt: `tvarkymo sąlygomis.`,
        uz: `shaxsiy ma'lumotlarni`
    },
    nuance2: {
        ru: '',
        en: '',
        lt: '',
        uz: `qayta ishlashga rozilik bildirasiz`
    }
};


export default about;
