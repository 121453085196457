import styled, { css } from 'styled-components';

import TypeSite from '@logic/enums/TypeSite';
import SiteType from "@logic/enums/TypeSite";
import about from '@logic/language/ex/landing/contacts/about';
import footer from '@logic/language/ex/landing/index/footer';
import useLanguage from '@hooks/useLanguage';

import {useAppSelector} from '@store/hooks/hooks';

import LinkButton, { LinkStyle } from '@components/common/buttons/LinkButton';
import { Text } from '@components/common/texts/typography/Typography';
import { Colors } from '@components/styledComponents/base/Colors';

import Callback from '@landing/components/Callback/Callback';

const socialNetworks = [
    {
        site: TypeSite.COM,
        href: 'https://t.me/pay_assistant',
        className: 'telegram',
        label: {
            ru: `Ссылка на телеграм`,
            en: `Link to Telegram account`,
            lt: `Nuoroda į Telegram paskyrą`,
            uz: `Telegram hisobiga havola`
        }
    },
    {
        site: TypeSite.RU,
        href: 'https://t.me/pay_assistant_ru',
        className: 'telegram',
        label: {
            ru: `Ссылка на телеграм`,
            en: `Link to Telegram account`,
            lt: `Nuoroda į Telegram paskyrą`,
            uz: `Telegram hisobiga havola`
        }
    },
    {
        site: TypeSite.RU,
        href: 'https://vk.com/pay_assistant',
        className: 'vk',
        label: {
            ru: `Ссылка на Ссылка на аккаунт Вконтанте`,
            en: `Link to the Vkontakte account`,
            lt: `Nuoroda į "Vkontante" paskyrą`,
            uz: `Vkontante hisob qaydnomasiga havola`
        }
    },
    {
        site: TypeSite.COM,
        href: 'https://instagram.com/payassistantcom',
        className: 'instagram',
        label: {
            ru: `Ссылка на instagram`,
            en: `Link to instagram account`,
            lt: `Nuoroda į instagram paskyrą`,
            uz: `instagram hisobiga havola`
        }
    },
    {
        site: TypeSite.COM,
        href: 'https://www.facebook.com/payassistantcom',
        className: 'facebook',
        label: {
            ru: `Ссылка на facebook`,
            en: `Link to facebook account`,
            lt: `Nuoroda į facebook paskyrą`,
            uz: `facebook hisobiga havola`
        }
    },
    {
        site: TypeSite.UZ,
        href: 'https://instagram.com/payassistant.uz',
        className: 'instagram',
        label: {
            ru: `Ссылка на instagram`,
            en: `Link to instagram account`,
            lt: `Nuoroda į instagram paskyrą`,
            uz: `instagram hisobiga havola`
        }
    },
    {
        site: TypeSite.UZ,
        href: 'https://www.facebook.com/payassistant.uz',
        className: 'facebook',
        label: {
            ru: `Ссылка на facebook`,
            en: `Link to facebook account`,
            lt: `Nuoroda į facebook paskyrą`,
            uz: `facebook hisobiga havola`
        }
    },
];

export default function Footer () {

    const {site, language} = useAppSelector(state => state.generalStatesSlice);
    const texts = useLanguage(footer);

    return (
        <>
            <Container>
                <Content>
                    <AddressData>
                        <FooterLogo/>
                        <Callback styleType='plainButton' data-test-id="footerCallbackButtonMobile" />
                        <AddressBlock>
                            {
                                site !== TypeSite.UZ && (
                                    <AddressSide>
                                        <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                            {about.companyTitle[language]}
                                        </Text>
                                        <Text font='body' color={Colors.GRAY_900} as ='p'>
                                            {about.company[language]}{` `}
                                        </Text>
                                    </AddressSide>
                                )
                            }
                            <AddressSide>
                                <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                    {(texts?.address[language] as string)?.toUpperCase()}
                                </Text>
                                <Text font='body' color={Colors.GRAY_900} as ='p'>
                                    {about.addressCountry[language]}{` `}
                                    {about.addressLocality[language]}{` `}
                                    {about.addressStreet[language]}
                                </Text>
                            </AddressSide>
                            <AddressSide>
                                <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                    {(texts?.workingRegime[language] as string)?.toUpperCase()}
                                </Text>
                                <Text font='body' color={Colors.GRAY_900} as ='p'>
                                    {texts.mondayFriday[language]} 09:00 - 19:00
                                </Text>
                            </AddressSide>
                        </AddressBlock>
                    </AddressData>

                    <LinksData>
                        <Section>
                            <Text 
                                font='bodyMedium'
                                color={Colors.GRAY_900}
                                as='p'
                                styles={sectionTitle}>
                                {texts.toWhom[language]}
                            </Text>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY} href={'/to-customers'}>
                                {texts.toCompanies[language]}                            
                            </LinkButton>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY}href={'/to-freelancers'}>
                                {texts.toFreelancers[language]}                       
                            </LinkButton>
                        </Section>
                        <Section>
                            <Text 
                                font='bodyMedium'
                                color={Colors.GRAY_900}
                                as='p'
                                styles={sectionTitle}>
                                {texts.company[language]}
                            </Text>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY} href={'/about'}>
                                {texts.aboutTheCompany[language]}
                            </LinkButton>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY} href={'/contacts'}>
                                {texts.contacts[language]}
                            </LinkButton>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY}href={'/faq'}>
                                {texts.FAQ[language]}
                            </LinkButton>
                        </Section>
                        <Section>
                            <Text 
                                font='bodyMedium'
                                color={Colors.GRAY_900}
                                as='p'
                                styles={sectionTitle}>
                                {texts.aboutTheService[language]}
                            </Text>
                            <LinkButton as='Link' linkStyle={LinkStyle.GRAY} href={'/functions-and-rates'}>
                                {texts.functionsAndRates[language]}
                            </LinkButton>
                        </Section>
                        <Section>
                            {/* <Link href={'/reward-program'}>
                                <Text 
                                    font='bodyMedium'
                                    color={Colors.GRAY_900}
                                    as='p'
                                    styles={sectionTitle}>
                                    {texts.rewardProgram[language]}
                                </Text>
                            </Link> */}
                            {/*<LinkButton */}
                            {/*    as='Link' */}
                            {/*    linkStyle={LinkStyle.GRAY} */}
                            {/*    href={'/documentation'}*/}
                            {/*    styles={sectionTitle}>*/}
                            {/*    {texts.documentation[language]}*/}
                            {/*</LinkButton>*/}
                            <LinkButton 
                                as='Link' 
                                linkStyle={LinkStyle.GRAY}
                                href={'/api/v1/documentation'}
                                styles={sectionTitle}>
                                {texts.Api[language]}
                            </LinkButton>
                        </Section>
                    </LinksData>
                    <AddressBlock>
                        {
                            site !== TypeSite.UZ && (
                                <AddressSide>
                                    <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                        {about.companyTitle[language]}
                                    </Text>
                                    <Text font='body' color={Colors.GRAY_900} as ='p'>
                                        {about.company[language]}{` `}
                                    </Text>
                                </AddressSide>
                            )
                        }
                        <AddressSide>
                            <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                {(texts?.address[language] as string)?.toUpperCase()}
                            </Text>
                            <Text font='body' color={Colors.GRAY_900} as ='p'>
                                {about.addressCountry[language]}{` `}
                                {about.addressLocality[language]}{` `}
                                {about.addressStreet[language]}
                            </Text>
                        </AddressSide>
                        <AddressSide>
                            <Text font='bodySmall' color={Colors.GRAY_600} as ='p'>
                                {(texts?.workingRegime[language] as string)?.toUpperCase()}
                            </Text>
                            <Text font='body' color={Colors.GRAY_900} as ='p'>
                                {(texts?.mondayFriday[language] as string)?.toUpperCase()} 09:00 - 19:00
                            </Text>
                        </AddressSide>
                    </AddressBlock>
                    <ContactData>
                        <Callback styleType='plainButton' data-test-id="footerCallbackButtonDesktop" />
                        <ContactsText>
                            {
                                site === SiteType.UZ && (
                                    <LinkButton
                                        as='a'
                                        linkStyle={LinkStyle.GRAY}
                                        href={`tel: ${process.env.NEXT_PUBLIC_PHONE}`}>
                                        {process.env.NEXT_PUBLIC_PHONE}
                                    </LinkButton>
                                )
                            }
                            <LinkButton 
                                as='a' 
                                linkStyle={LinkStyle.GRAY}  
                                href={`mailto: ${process.env.NEXT_PUBLIC_EMAIL}`}>
                                {process.env.NEXT_PUBLIC_EMAIL}
                            </LinkButton>
                        </ContactsText>
                        <SocialNetwork>
                            {socialNetworks.filter(item => item.site === site).map((item, i) => (
                                <a
                                    key={i}
                                    href={item.href}
                                    className={item.className}
                                    aria-label={item.label[language]}
                                    target="_blank"
                                    rel="noreferrer"
                                />
                            ))}
                        </SocialNetwork>
                    </ContactData>
                </Content>
            </Container>
            <SecondContainer>
                <Content>
                    <Text font='bodySmall' color={Colors.GRAY_500}>
                        {site === TypeSite.RU && ('Freelconsult computer systems & communication equipment software design - FZE')}
                        {site === TypeSite.UZ && ('ООО "PAY ASSISTANT U" ИНН 309577723')}
                        {site === TypeSite.COM && (`PayAssistant ©${new Date().getFullYear()}`)}
                    </Text>

                    {
                        site === SiteType.COM &&
		                <DocsContainer>
			                <LinkButton 
		                        font='captions'
		                        linkStyle={LinkStyle.WHITE}
		                        as='a'
		                        >
				                {texts.theOffer[language]}
			                </LinkButton>
			                <LinkButton 
		                        font='captions'
		                        linkStyle={LinkStyle.WHITE}
		                        as='Link' 
		                        href={'/'}
		                        target={'_blank'}>
				                {texts.privacyPolicy[language]}
			                </LinkButton>
			                <LinkButton 
		                        font='captions'
		                        linkStyle={LinkStyle.WHITE}
		                        as='Link' 
		                        href={'/'}
		                        target={'_blank'}>
				                {texts.userAgreement[language]}
			                </LinkButton>
		                </DocsContainer>
                    }
                    {
                        site === SiteType.RU &&
                      <DocsContainer>
                          {/*<LinkButton*/}
                          {/*    font='captions'*/}
                          {/*    linkStyle={LinkStyle.WHITE}*/}
                          {/*    as='Link'*/}
                          {/*    href={'/rules-of-use'}*/}
                          {/*    target={'_blank'}>*/}
                          {/*    {texts.rulesOfUse[language]}*/}
                          {/*</LinkButton>*/}
                          {/*<LinkButton*/}
                          {/*    font='captions'*/}
                          {/*    linkStyle={LinkStyle.WHITE}*/}
                          {/*    as='Link'*/}
                          {/*    href={'/privacy-policy'}*/}
                          {/*    target={'_blank'}>*/}
                          {/*    {texts.privacyPolicy[language]}*/}
                          {/*</LinkButton>*/}
                      </DocsContainer>
                    }
                </Content>
                {site === TypeSite.RU &&
                      <ReviewContainer>
                          <iframe src="https://yandex.ru/sprav/widget/rating-badge/185507029549?type=rating&theme=dark" width="150" height="50" scrolling='no' frameBorder="0"></iframe>
                      </ReviewContainer>}
            </SecondContainer>
        </>

    );
}

const sectionTitle = css`
  padding-bottom: 8px;
`;

const Container = styled.div`
  background: #E3EFF1;
  padding: 64px 0;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 16px 0 32px 0;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  padding: 0 20px;
  gap: 32px;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 30px;
  }
  @media (min-width: 1000px) {
    &>:nth-child(3) {
      display: none;
    }
  }

`;

const AddressData = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (min-width: 1000px) {
    flex-direction: column;
  }

  @media (min-width: 1000px) {
    &>:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    &>:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: 1000px) {
    &>:last-child {
      display: none;
    }
  }

  @media (max-width: 780px) {
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 780px) {
    align-items: center;
    justify-content: space-between;
    &>:last-child {
      display: none;
    }
  }
`;

const FooterLogo = styled.div`
  background: url("/landing/header/logo_black.svg") no-repeat;
  width: 280px;
  height: 36px;
  @media (max-width: 1000px) {
    width: 193px;
    height: 26px;
  }
  @media (max-width: 1000px) {
    width: 134px;
    height: 18px;
  }
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 540px) {
    gap: 20px;
    justify-content: space-between;
  }
`;

const AddressSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinksData = styled.div`
  margin-left: auto;
  display: grid;
  gap: 32px 96px;
  grid-template-columns: repeat(2, 150px);

  @media (max-width: 1000px) {
    gap: 32px;
    grid-template-columns: repeat(4, 150px);
    margin-left: unset;
  }
  @media (max-width: 740px) {
    grid-template-columns: repeat(auto-fill, 44%);
    margin-left: unset;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;

  @media (max-width: 1000px) {
    &>:first-child {
      display: none;
    }

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media (max-width: 540px) {
    flex-direction: column;
  }

`;

const ContactsText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  @media (max-width: 1000px) {
    align-items: flex-start;
  }
`;

const SocialNetwork = styled.div`
  display: flex;
  gap: 24px;
`;

const SecondContainer = styled(Container)`
  padding: 30px 0;
  background: ${Colors.GRAY_900};
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1000px) {
    gap: 30px;
  }
`;

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  border: none;
  overflow: hidden;
`;

const DocsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding-left: 0;
  align-items: flex-end;
  gap: 24px;

  @media (max-width: 1000px) {
    order: -1;
  }
`;