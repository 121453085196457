import React from 'react';

const about = {
    title: {
        ru: (
            <>
        У вас есть вопросы о сервисе? <br /> Напишите нам прямо сейчас
            </>
        ),
        en: (
            <>
        Do you have any questions left? <br /> Write to us right now
            </>
        ),
        lt: (
            <>
        Ar nori eiti į darbą? <br /> Galėsi įsidarbinti
            </>
        ),
        uz: <>
            Sizda xizmat haqida savollar bormi?
            <br/>
            Bizga hoziroq yozing!
        </>
    },
    companyTitle: {
        ru: `Компания`,
        en: `Company`,
        lt: `Įmonė`,
        uz: `Kompaniya`
    },
    button: {
        ru: `Написать`,
        en: `Write`,
        lt: `Parašyk žinutę`,
        uz: `yozish `
    },
    company: {
        ru: <>Freelconsult computer systems & <br/>communication equipment software design - FZE<br/>Reg.number: 1004</>,
        en: <>Freelconsult computer systems & <br/>communication equipment software design - FZE<br/>Reg.number: 1004</>,
        lt: <>Freelconsult computer systems & <br/> communication equipment software design - FZE<br/>Reg.number: 1004</>,
        uz: <>Freelconsult computer systems & <br/> communication equipment software design - FZE<br/>Reg.number: 1004</>,
    },
    phone: {
        ru: `Телефон`,
        en: `Phone number`,
        lt: `Telefonas`,
        uz: `telefon`,
    },
    email: {
        ru: `Почта`,
        en: `Email`,
        lt: `paštas`,
        uz: `Pochta`
    },
    address: {
        ru: `Адрес`,
        en: `address `,
        lt: `adresas`,
        uz: `Manzil`
    },
    addressAtMap: {
        ru: <>Кватер Дек, Мина Рашид-Куин Элизабет II, П.О.<br/> бокс - 554789, Дубай, ОА</>,
        en: <>Quater Deck, Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789, Dubai, UAE</>,
        lt:  <>Quater Deck, Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789, Dubai, UAE</>,
        uz:  <>Quater Deck, Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789, Dubai, UAE</>,
    },
    addressCountry: {
        ru: `UAE`,
        en: `UAE`,
        lt: `UAE`,
        uz: `UAE`
    },
    addressLocality: {
        ru: `Дубай`,
        en: `Dubai`,
        lt: `Dubai`,
        uz: `Dubai`
    },
    addressStreet: {
        ru: <>Кватер Дек <br/>Мина Рашид-Куин Элизабет II, П.О.<br/> бокс - 554789</>,
        en: <>Quater Deck<br/> Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789</>,
        lt:  <>Quater Deck<br/> Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789</>,
        uz:  <>Quater Deck<br/> Mina Rashid-Queen Elizabeth II, P.O.<br/> box - 554789</>,
    },
    formTitle: {
        ru: `Задать вопрос`,
        en: `Ask a question `,
        lt: `Užduoti klausimą`,
        uz: `Savol bering`
    },
    question: {
        ru: `Ваш вопрос`,
        en: `Question `,
        lt: `jūsų`,
        uz: `Sizning savolingiz`
    },
    name: {
        ru: `Имя`,
        en: `Name `,
        lt: `Vardas`,
        uz: `Ismi`
    },
    nuance: {
        ru: `Нажимая на кнопку, вы даете согласие на обработку`,
        en: `By clicking the 'Apply' button you accept the terms of`,
        lt: `Spausdami mygtuką „Pateikti paraišką“ sutinkate su`,
        uz: `Tugmani bosish orqali siz`
    },
    personalData: {
        ru: `персональных данных`,
        en: `personal formData processing.`,
        lt: `tvarkymo sąlygomis.`,
        uz: `shaxsiy ma'lumotlarni`
    },
    nuance2: {
        ru: '',
        en: '',
        lt: '',
        uz: `qayta ishlashga rozilik bildirasiz`
    }
};


export default about;
