import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';

import SiteType from '@logic/enums/TypeSite';
import RuleMessage from '@logic/forms/validators/rules/RuleMessage';
import Helper from '@logic/helpers/Helper/Helper';
import labels from '@logic/language/ex/landing/labels/labels';
import header from '@logic/language/ru/landing/index/header';
import useOnClickOutside from '@hooks/UseOnClickOutside';

import {useAppSelector} from '@store/hooks/hooks';
import {setLang} from '@store/reducers/generalStates/generalStatesSlice';

import { Text } from '@components/common/texts/typography/Typography';
import {Colors} from '@components/styledComponents/base/Colors';
import { hover, mediaBreakpointDown, mediaBreakpointUp } from '@components/styledComponents/base/functions';
import { typography } from '@components/styledComponents/base/typography';
import { Z_INDEX } from '@components/styledComponents/base/vars';

import ArrowIcon from '@public/icons/baseUI/arrow-icons/arrowDown_new.svg';
import ProfileIcon from '@public/landing/header/profile_new.svg';

import Callback from '@landing/components/Callback/Callback';

const SWITCH_TO_BURGER_BREAKPOINT = 1256;

interface HeaderStateProps { isOnTop: boolean, isOpen: boolean }

interface Link {
    link: string,
    text: RuleMessage,
    show: boolean
}

const links: Array<Link> = [
    {
        link: `to-customers`,
        text: header.toCustomersText,
        show: true
    },
    {
        link: `to-freelancers`,
        text: header.freelancersText,
        show: true
    },
    {
        link: `functions-and-rates`,
        text: header.featuresAndTariffs,
        show: true
    },
    {
        link: 'about',
        text: header.AboutTheCompany,
        show: true
    },
    {
        link: 'faq',
        text:  header.FAQText,
        show: true
    },
    // {
    //     link: 'contacts',
    //     text:  header.ContactsText,
    //     show: true
    // },
    // {
    //     link: 'api/v1/documentation',
    //     text: header.Api,
    //     show: true
    // }
];

type Props = {
    isWhiteTheme?: boolean;
};

export default function Header ({isWhiteTheme}: Props) {
    const {language, site, availableLanguages} = useAppSelector(state => state.generalStatesSlice);

    const dispatch = useDispatch();
    const refLang = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const [scrollIsOnTop, setScrollIsOnTop] = useState(!isWhiteTheme);
    const [langSelectIsOpen, setLangSelectIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const hasScroll = () => document.documentElement.scrollTop > 15;

    const isActiveRoute = (link: Link) => router.asPath.includes(`/${link.link}`);

    useOnClickOutside(ref, () => {
        setIsOpen(false);
    });

    useOnClickOutside(refLang, () => {
        setLangSelectIsOpen(false);
    });

    const closeMenuForDesktop = () => {
        if(window.innerWidth > 1200) {
            setIsOpen(false);
        }
    };

    const headerFix = () => {
        setScrollIsOnTop(!hasScroll() && !isWhiteTheme);
    };

    useEffect(() => {
        addEventListener(`scroll`, headerFix);
        addEventListener(`resize`, closeMenuForDesktop);
        return () => {
            setIsOpen(false);
            removeEventListener(`scroll`, headerFix);
            removeEventListener(`resize`, closeMenuForDesktop);
        };
    }, []);

    const renderLinks = () => links.map((link, index) => (
        <Link href={`/${link.link}`} key={index}>
            <PageLink
                isActive={isActiveRoute(link)}
                isOnTop={isOpen ? false : scrollIsOnTop}>
                {link.text[language]}
            </PageLink>
        </Link>
    ));

    return (
        <>
            <HeaderVoid />
            <Container isOnTop={scrollIsOnTop}>
                <Content>
                    <BurgerButton
                        aria-label={labels.menuOpen[language]}
                        isOnTop={scrollIsOnTop}
                        onClick={()=>setIsOpen(true)}>
                        <BurgerImg alt='menu icon' src={'/landing/header/Hamburger.svg'}/>
                        <Text font='body'>
                            {header.menuText[language]}
                        </Text>
                    </BurgerButton>
                    <Link href={`/`}>
                        <Logo isOnTop={scrollIsOnTop}/>
                    </Link>

                    <LinksContainer isOpen={isOpen} ref={ref}>
                        <LinksHeader>
                            <CloseButton
                                aria-label={labels.menuClose[language]}
                                onClick={()=>setIsOpen(false)}>
                                <CloseImg alt='close icon' src={'/icons/baseUI/cross-icons/CloseIcon.svg'}/>
                                <Text font='body'>
                                    {header.menuText[language]}
                                </Text>
                            </CloseButton>
                            <Link href={`/`}>
                                <MenuLogo />
                            </Link>
                        </LinksHeader>
                        <Links >
                            {renderLinks()}
                        </Links>
                    </LinksContainer>

                    {
                        site === SiteType.COM && (
                            <ComContainer>
                                <Link href={'/sign-in'}>
                                    <SignButton isOnTop={scrollIsOnTop}>
                                        <ProfileIcon/>
                                        <span>
                                            {header.SignInText[language]}
                                        </span>
                                    </SignButton>
                                </Link>
                            </ComContainer>
                        )
                    }
                    {
                        site !== SiteType.COM && (
                            <ComContainer>
                                <LangButton 
                                    onClick={()=>setLangSelectIsOpen(prev => !prev)} ref={refLang}>
                                    <SelectedLangWrapper 
                                        isOnTop={scrollIsOnTop} 
                                        isOpen={langSelectIsOpen}>
                                        <FlagImg src={`/landing/header/${language}.svg`} />
                                        <Text color={Colors.GRAY_900}>{language.toUpperCase()}</Text>
                                        <ArrowIcon />
                                    </SelectedLangWrapper>
                                    
                                    <LanguageList isOnTop={scrollIsOnTop} isOpen={langSelectIsOpen}>
                                        {
                                            availableLanguages.filter(lang => lang !== language).map((lang, index) => (
                                                <LangContainer
                                                    key={index}
                                                    tabIndex={langSelectIsOpen ? 0 : -1}
                                                    role='option'
                                                    aria-label={lang}
                                                    aria-selected={lang === language}
                                                    onClick={() => dispatch(setLang(lang))}
                                                    onKeyDown={(e) => Helper.handleEnterKeydown(e, () => dispatch(setLang(lang)))}
                                                >
                                                    <FlagImg src={`/landing/header/${lang}.svg`}/>
                                                    <Text color={Colors.GRAY_900}>{lang.toUpperCase()}</Text>
                                                    
                                                </LangContainer>
                                            ))
                                        }
                                    </LanguageList>
                                </LangButton>
                                <Callback data-test-id="headerCallbackButton" styleType='stroke' isWhite={scrollIsOnTop} />
                            </ComContainer>
                        )
                    }
                </Content>
            </Container>
        </>
    );
}

const ComContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const FlagImg = styled.img`
  height: 18px;
  width: 22px;
  object-fit: cover;
  border-radius: 1px;
`;

const SignButton = styled.div<{isOnTop: boolean}>`
  ${typography.text_s};
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  height: 34px;
  width: max-content;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 14px;
  transition:  background-color 0.5s, border 0.5s, color 0.5s;
  color: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
  border: ${({ isOnTop }) => isOnTop ? `1px solid ${Colors.GRAY_400}`
        : `1px solid ${Colors.GRAY_800}`};

  svg {
      height: 20px;
      width: 20px;
      transition: stroke 0.5s;
      stroke: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
    }

  ${hover(css`
      background-color: ${({ isOnTop }: any) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
      color: ${({ isOnTop }: any) => isOnTop ? Colors.GRAY_800 : Colors.WHITE};

      svg {
        stroke: ${({ isOnTop }: any) => isOnTop ? Colors.GRAY_800 : Colors.WHITE};
      }
  `)};

  @media(max-width: 480px) {
    padding: 5px;
    border-radius: 50%;
    &>:nth-child(2) {
      display: none;
    }
  }
`;

const SelectedLangWrapper = styled.div<HeaderStateProps>`
  cursor: pointer;
  border-radius: 10px;
  height: 34px;
  width: max-content;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: 15px 14px;
  transition:  background-color 0.5s, border 0.5s;
  border: ${({ isOnTop }) => isOnTop ? `1px solid ${Colors.GRAY_400}`
        : `1px solid ${Colors.GRAY_800}`};

  span {
    color: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
  }

  svg {
    transition: transform 500ms;
    transform: ${({ isOpen }) => isOpen ? 'scaleY(-1)' : 'scaleY(1)'};
    stroke: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
  }

  ${hover(css`
      background-color: ${({ isOnTop }: any) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
      span {
        color: ${({ isOnTop }: any) => isOnTop ? Colors.GRAY_800 : Colors.WHITE};
      }

      svg {
        stroke: ${({ isOnTop }: any) => isOnTop ? Colors.GRAY_800 : Colors.WHITE};
      }
  `)};

  @media (max-width: 480px) {
    padding: 5px;
    &>:nth-child(2) {
      display: none;
    }
  }
`;

const LangButton = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LanguageList = styled.div<HeaderStateProps>`
  display: flex;
  padding: 2px;
  flex-direction: column;
  width: 100%;
  position: absolute;
  transition: top 0.5s, opacity 0.5s, visibility 0.5s;
  top: ${({isOpen}) => isOpen ? '120%' : '100%'};
  opacity: ${({isOpen}) => isOpen ? '1' : '0'};
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  pointer-events: ${({isOpen}) => isOpen ? 'all' : 'none'};
  background: ${Colors.WHITE};
  box-shadow:${({ isOnTop }) => isOnTop ? `unset` : `0px 4px 64px rgba(0, 0, 0, 0.1)`};
  border-radius: 8px;
`;


// const NestedLinksWrapper = styled.div<HeaderStateProps & {isActive?: boolean}>`
//   position: relative;
//   ${typography.body};
//   font-weight: 400;
//   font-size: 14px;
  
//   border-radius: 10px;
//   height: 34px;
//   width: max-content; 
//   display: flex;
//   justify-content: center;
//   gap: 8px; 
//   align-items: center;
//   padding: 8px;
//   transition:  background-color 0.5s, color 0.5s;
//   color: ${({isOnTop}) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
//   background-color: ${({ isOnTop, isActive }) => (isOnTop || !isActive) ? 'transparent'
//         : '#EBEEF7'};
//   cursor: pointer;

//   svg {
//     position: relative;
//     top: 0.5px;
//     transition: transform 0.5s, stroke 0.5s;
//     transform: ${({ isOpen }) => isOpen ? 'scaleY(-1)' : 'scaleY(1)'};
//     stroke: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
//   }

//   ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
//     font-size: 20px;
//   }
// `;

// const NestedLinks = styled.div<HeaderStateProps>`
//   width: max-content;
//   position: absolute;
//   display: grid;
//   padding: 2px;
//   transition: top 0.5s, opacity 0.5s, visibility 0.5s;
//   top: ${({ isOpen }) => isOpen ? '120%' : '100%'};
//   opacity: ${({ isOpen }) => isOpen ? '1' : '0'};
//   visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
//   pointer-events: ${({ isOpen }) => isOpen ? 'all' : 'none'};
//   background-color: ${Colors.WHITE};
//   box-shadow:${({ isOnTop }) => isOnTop ? `unset` : `0px 4px 64px rgba(0, 0, 0, 0.1)`};
//   border-radius: 8px;
// `;

const LangContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  transition:  background-color 0.5s;

  span {
    color: ${Colors.GRAY_800};
  }

  ${hover(css`
      background-color: ${Colors.GRAY_300};
  `)};

  @media (max-width: 480px) {
    padding: 5px;
    &>:nth-child(2) {
        display: none;
    }
  }
`;

const Container = styled.div<{isOnTop: boolean}>`
  z-index: ${Z_INDEX.header};
  top: 0;
  left: 0;
  transition: .5s;
  position: fixed;
  background: ${({isOnTop}) => isOnTop ? `transparent` : `#FFFFFF`};
  box-shadow:${({isOnTop}) => isOnTop ? `unset` : `0px 4px 64px rgba(0, 0, 0, 0.1)`};
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: 0 48px;
  
  @media (max-width: 750px) {
    padding: 0 24px;
  }

  @media (max-width: 375px) {
    padding: 0 16px;
  }
`;

const HeaderVoid = styled.div`
  min-height: 80px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  height: 44px;
`;

const BurgerButton = styled.button<{isOnTop: boolean}>`
  cursor: pointer;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px 8px 8px;
  gap: 4px;
  min-width: 75px;
  height: 34px;
  background: ${({isOnTop}) => isOnTop ? '#FFFFFF' : Colors.BG_LIGHT_BLUE};
  border-radius: 10px;

  ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
    display: flex;
  }

  @media (max-width: 550px) {
    width: 34px;
    height: 34px;
    padding: 0;
    & > :nth-child(2) {
      display: none;
    }
  }

`;

const CloseButton = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 4px;
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.GRAY_900};
  border-radius: 10px;
  width: 73px;
  height: 34px;

  ${mediaBreakpointUp(SWITCH_TO_BURGER_BREAKPOINT)}{
    display: none;
  }

  @media (max-width: 550px) {
    width: 34px;
    height: 34px;
    padding: 0;
    & > :nth-child(2) {
      display: none;
    }
  }
  
`;

const BurgerImg = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

const CloseImg = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

const Logo = styled.div<{isOnTop: boolean}>`
  cursor: pointer;
  transition: .5s;
  background: url("/landing/header/${({isOnTop}) => isOnTop ? 'logo_white.svg' : 'logo_black.svg'}") no-repeat;
  width: 162px;
  height: 22px;

  @media (max-width: 550px) {
    width: 107px;
    height: 14px;
  }
`;

const MenuLogo = styled.span`
  @media (min-width: 540px) {
    display: none;
  }
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  cursor: pointer;
  transition: .5s;
  background: url("/landing/header/logo_black.svg") no-repeat;
  width: 107px;
  height: 14px;
`;

const LinksHeader = styled.div`
  display: none;
  ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LinksContainer = styled.div<{isOpen: boolean}>`
  ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
    transition: .5s;
    position: fixed;
    z-index: 9;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 23px;
    background: #FFFFFF;
    box-shadow: 0px 10px 64px rgba(0, 0, 0, 0.11);
    border-radius: 0px 20px 20px 0px;
    width: 320px;
    height: 100%;
    top: 0;
    left: ${({ isOpen }) => isOpen ? `0` : `-320px`};
  }

  @media (max-width: 540px) {
    width: 100%;
    left: ${({isOpen}) => isOpen ? `0` : `-100%`};
    border-radius: 0;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding-top: 16px;
  }
`;

const PageLink = styled.span<{isOnTop: boolean, isActive?: boolean}>`
  ${typography.body};
  padding: 8px;
  border-radius: 4px;
  transition: color .5s, background-color 0.5s;
  color: ${({ isOnTop }) => isOnTop ? Colors.WHITE : Colors.GRAY_800};
  background-color: ${({ isOnTop, isActive }) => (isOnTop || !isActive) ? 'transparent' 
        : '#EBEEF7'};
  cursor: pointer;
  ${mediaBreakpointDown(SWITCH_TO_BURGER_BREAKPOINT)}{
    font-size: 20px;
  }
`;

// const NestedPageLink = styled(PageLink)` 
//   padding: 12px;
//   border-radius: 8px;
//   display: inline-block;
//   width: 100%;

//   ${hover(css`
//       background-color: ${Colors.GRAY_300};
//   `)}
// `;


