import com from '@logic/language/com/landing/index/footer';
import ru from '@logic/language/ru/landing/index/footer';
import uz from '@logic/language/uz/landing/index/footer';


export default {
    ru,
    com,
    uz
};


